<template>
		<div>
			<div 
				class="campaign_cont"
				v-if="campaign!=null&&$root.investor.loggedIn==true"
			>
				<div class=full>
					<router-link
						:to="'/campaigns/' + this.$root.investor.uid + '/'"
					>
						My Campaigns
					</router-link>
					<div 
						v-if="$root.hasRole('ma')==true"
						class=tag
					>
						| &nbsp; &nbsp;
						<router-link
							to="/campaigns/all/"
						>
							All Campaigns
						</router-link>
					</div>
					<br>
					Created on {{ campaign.readableCreationTimestamp }}
				</div>
				<div class="full">
					Created By 
					<router-link
						:to="'/campaigns/'+campaign.creator_id"
					>
						{{ campaign.first_name }} {{ campaign.last_name }} 
					</router-link>
					<br>
					<br>

					<v-text-field
						label="Campaign Title"
						v-model=campaign.title
					>
					</v-text-field>
					<v-text-field
						id="regarding"
						name="regarding"
						label="Regarding"
						v-model=campaign.regarding
					>
					</v-text-field>
					<v-text-field
						label="X-Campaign"
						v-model=campaign.x_campaign
					>
					</v-text-field>
					<v-select
						@change="updateNumContacts()"
						:label="'Market (' +marketSize+ ')'"
						v-model=campaign.market
						:items=markets
						item-text="name"
						item-value="id"	
					>
					</v-select>
					<div>
						<div class="v-label v-label--active theme--light">Autofill Options</div>
						<v-menu offset-y
							v-for="word in autofillOptions"
							:key="word"
						>
							<template v-slot:activator="{on, attrs}">
							<v-btn
								depressed
								class="tag yellow lighten-4"
								v-bind="attrs"
								v-on="on"
							>
								{{ word }}
							</v-btn>
							</template>
							<v-list>
								<v-list-item
									@click="insertAutofill(word, 'body')"
								>
									<v-list-item-title>Insert Into Message</v-list-item-title>
								</v-list-item>
								<v-list-item
									@click="insertAutofill(word, 'regarding')"
								>
									<v-list-item-title>Insert Into Regarding</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
					<br>
					<v-select
						label="From Address"
						v-model=campaign.from_addr
						:items=dialog_email
						item-text="option"
						item-value="value"	
					>
					</v-select>
					<v-select
						label="Reply Address"
						v-model=campaign.reply_addr
						:items=dialog_email
						item-text="option"
						item-value="value"	
					>
					</v-select>
                                        <v-checkbox
						class="tag"
                                                v-model=campaign.read_receipt
                                                label="Request Read Receipt"
                                        >
					</v-checkbox>
                                        <v-checkbox
						class="tag"
                                                v-model=campaign.is_html
                                                label="Is HTML"
                                        >
					</v-checkbox>
					<div v-if="campaign.executed==false">
						<v-btn
							class="tag"
							:loading="saving"
							outlined
							v-on:click='save($event)'
						>
							Save
						</v-btn>
						<v-btn
							class="tag"
							:loading="sending"
							outlined
							v-on:click='send($event)'
						>
							Send	
						</v-btn>
					</div>
					<br>
					<br>
					<div v-if="dirty">
						<i>Changed</i>
					</div>

					<v-tabs
						v-model="tab"
					>
						<v-tab> 
							Text
						</v-tab>
						<v-tab
							v-if="campaign.is_html"
						>
							HTML
						</v-tab>
					</v-tabs>
					
					<v-tabs-items
						v-model="tab"
					>
						<v-tab-item>
							<v-textarea
								height="500px"
								outlined
								name="body"
								id="body"
								label="Body"
								v-model="campaign.body"
								v-on:change="changed()"
							>
							</v-textarea>
						</v-tab-item>
						<v-tab-item>
							<div class=preview>
								<span
									v-html="campaign.body"
								>
								</span>
							</div>
						</v-tab-item>
					</v-tabs-items>
					Campaign History	
					<div 
						v-for="log in logs"
						:key="log.id"
					>
						<v-icon v-if="log.ts_start==0||log.ts_finish==0"> mdi-email-alert-outline </v-icon>
						<v-icon v-else > mdi-email-outline </v-icon>
						Sent <b>{{ getReadableDate(log.ts_start) }}</b> by <b>{{ log.sender_id }}</b> to <b>{{ log.market_name }}</b> as <b>{{ log.campaign_name }}</b>.
					</div>

				</div>	
			</div>
			<div
				class="campaign_cont center"
				v-if="$root.investor.loggedIn==false"
			>
				<router-link
					to="/"
				>
					Home
				</router-link>
				<h1> You Have To Be Logged In To View Campaigns </h1>
			</div>
			<div 
				v-if="campaign==null"
				class="campaign_cont center"
			>
				<router-link
					:to="'/campaigns/' + campaign.campaign_id + '/'"
				>
					All Campaigns
				</router-link>
				<h1> This Campaign Does Not Exist </h1>
			</div>
		</div>
</template>

<script>
module.exports = {
	data() {
		return {
			marketSize: 0,
			tab: null,
			dirty: false,
			logs: [],	
			dialog_email: [],
			markets: [],
			market: {},
			market_id_map: {},
			autofillOptions: [],	
			campaign: {
				excecuted: false,
				campaign_id: -1,
				title: "",
				image: "",
				published: false,
				body: "",
			},
			saving: false,
			sending: false,
			titleFontStyle: {},
			titleFontStyleMobile: {},
			textFontStyle: {},
			textFontStyleMobile: {}, };
	},
	methods: {
		insertAutofill(af, el) {
			var ins = " {{" + af + "}} "
			var ta = document.getElementById(el)
			var cursor = ta.selectionStart
			var text = ""
			var new_text = ""

			if(el=="body") {
				text = this.campaign.body 
				new_text = text.slice(0,cursor)+ins+text.slice(cursor)
				this.campaign.body = new_text
			} else if(el=="regarding") {
				text = this.campaign.regarding
				new_text = text.slice(0,cursor)+ins+text.slice(cursor)
				this.campaign.regarding = new_text
			}
		},
		changed() {
			this.dirty = true;
		},
	
		send(e)	 {
			var self = this

			if(confirm("Are you sure you want to send this campaign?  This cannot be undone.")==false) {
				return;
			}

			this.save(e, function() {
				var ep = "/svc/campaign/execute/"+self.campaign.campaign_id+"/"

				var headers = {
					'Content-Type': 'application/json;charset=utf-8'
				};

				headers['USERNAME'] = self.$root.investor.username;
				headers['PASSWORD'] = self.$root.investor.password;
				headers['SESSION'] = self.$root.investor.sessionKey;

				self.sending = true;

				self.$http.put(ep, {}, { headers }).then(response => {
					self.sending = false;	
					if(response.data.code==200) {
						alert("Sent");
						self.updateLogs();
					} else {
						alert("Unable to save:  " + response.data.description);
					}
				})
			})
		},

		save(e, cb) {

			if(this.campaign==null||this.campaign.campaign_id==-1) {
				return;
			}

			this.saving = true;	

			var m = {};
			m.campaign_id = this.campaign.campaign_id;
			m.title = this.campaign.title;
			m.regarding = this.campaign.regarding;
			m.x_campaign = this.campaign.x_campaign;
			m.reply_addr = this.campaign.reply_addr;
			m.from_addr = this.campaign.from_addr;
			m.body = this.utf8_to_b64(this.campaign.body);
			m.market = this.campaign.market;
			m.is_html = this.campaign.is_html;
			m.read_receipt = this.campaign.read_receipt;

			console.log("POSTing:\n" + JSON.stringify(m))

			var ep = "/svc/campaign/"+this.campaign.campaign_id+"/"
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			this.$http.post(ep, m, { headers }).then(response => {
				this.saving = false;	
				if(response.data.code==200) {
					if(cb) {
						cb();
					} else {
						alert("saved");
					}
					this.dirty = false;
				} else {
					alert("Unable to save:  " + response.data.description);
				}
			})
			
		},

		updateLogs() {
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			var ep = "/svc/campaign/logs/"+this.campaign.campaign_id+"/"

			this.$http.get(ep, { headers }).then(response => {
				this.logs = response.data.logs
			})
		},

		getReadableDate(ts) {
			console.log(ts);
			var t = new Date(ts*1000);
			//var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
			var options = { year: 'numeric', month: 'long', day: 'numeric' };
			var times = t.toLocaleDateString("en-US", options);
			console.log(times);
			return(times);
		},

		utf8_to_b64( str ) {
			return window.btoa(unescape(encodeURIComponent( str )));
		},

		b64_to_utf8( str ) {
			return decodeURIComponent(escape(window.atob( str )));
		},
		updateNumContacts() {
			console.log("UpdateNumContacts");
			var ep = "/svc/market/size/"+this.campaign.market+"/"
			var headers = {
				'Content-Type': 'application/json;charset=utf-8'
			};

			headers['USERNAME'] = this.$root.investor.username;
			headers['PASSWORD'] = this.$root.investor.password;
			headers['SESSION'] = this.$root.investor.sessionKey;

			this.$http.get(ep, { headers }).then(response => {
				this.marketSize = response.data.size
			})
		},
		updateAutofillOptions() {
			var idx = this.market_id_map[this.campaign.market]
			console.log(idx)
			this.autofillOptions = this.markets[idx].autofillOptions
		}
	},
	updated() {
		console.log("updated");
	},
	activated() {
		console.log("activated");
	},
	unmounted() {
		console.log("unmounted");
	},
	mounted() {
		console.log("Logged in: " + JSON.stringify(this.$root.$data));
		var headers = {
			'Content-Type': 'application/json;charset=utf-8'
		};

		headers['USERNAME'] = this.$root.investor.username;
		headers['PASSWORD'] = this.$root.investor.password;
		headers['SESSION'] = this.$root.investor.sessionKey;

		this.$http.get("/svc"+this.$route.path.replace("editCampaign","campaign"), { headers }).then(response => {
			this.campaign = response.data.messageList?response.data.messageList[0]:null;
			this.campaign.body = this.b64_to_utf8(this.campaign.body);
			this.dialog_email = response.data.dialog_email;

			// there will be a lot of markets ultimately so sort them
			this.markets = response.data.markets.sort(function compare(f1,f2) {
				var diff = f1.name.localeCompare(f2.name)
				return(diff)
			});

			// split out keywords from markets
			for(var m in this.markets) {
				this.markets[m].autofillOptions = this.markets[m].column.replace(/\s/g,'').split(",")
				this.markets[m].autofillOptions[this.markets[m].autofillOptions.length-1]="email"; // the last option is always email, by definition
				this.market_id_map[this.markets[m].id] = m 
			} 
			console.log(this.markets[m].autofillOptions)
			console.log("Market ID Map: " + JSON.stringify(this.market_id_map));

			this.dialog_email.push(this.$root.investor.Email);
			this.dialog_email.push(this.campaign.email);

			this.titleFontStyle = response.data.global.title_font_style;
			this.titleFontStyleMobile = response.data.global.title_font_style_mobile;
			this.textFontStyle = response.data.global.text_font_style;
			this.textFontStyleMobile = response.data.global.text_font_style_mobile;

			this.campaign["readableCreationTimestamp"] = this.getReadableDate(this.campaign.created_ts);
			console.log("TS: " + this.campaign["readableCreationTimestamp"]);

			try {
				this.campaign.body=this.b64_to_utf8(this.campaign.body);
			} catch(e) {
				console.log(e);
			}

			this.updateNumContacts();
			this.updateAutofillOptions();
			this.updateLogs();
		})

	}

};
</script>

<style scoped>

.tag {
	display: inline-block;
	padding: 2px;
	border-width: 2px;
	margin: 3px;
}

.preview {
	min-height: 800px;
	border-style: solid;
	border-width: 1px;
	border-radius: 3px;
	padding: 5px;
}

.campaign_cont {
	max-width: 1200px;
	height: 100%;
	margin: 0 auto;
	align-items: center;
	justify-content: center;
}

.full {
}

.left_cont {
	display: inline;
	width: 20%;
	float: left;
}
.right_cont {
	display: inline;
	width: 20%;
	float: left;
}
.main_cont {
	display: inline;
	width: 60%;
	float: left;
}
.primary_img {
	max-height: 200px;
	max-width: 700px;
	object-fit: cover;
}

.mobile {
	margin: 0px 10px 0px 10px;
}

</style>


<style>
blockquote {
	padding: 0 1em;
	border-left: 3px solid rgba(0,0,0,.05);
	margin: 0 0 1.41575em 3em;
	font-style: italic;
}
hr {
	width:80%;
	text-align:left;
	margin-left:0;
	color: rgba(0,0,0,0.05);
}
ol, ul {
	margin: 0 0 1.41575em 3em;
	padding: 0;
}
</style>
